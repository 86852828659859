export const isEventSharedLink = (eventName = 'comp') =>
  document.location.href.includes(`/a/login/e/${eventName}/`) ||
  document.location.href.includes(`/a/register/e/${eventName}/`) ||
  document.location.href.includes(`/a/forgot-password/e/${eventName}/`);

export const getAssessmentIdFromSharedUrl = (eventName = 'comp') => {
  if (!isEventSharedLink(eventName)) {
    return '';
  }
  const url = document.location.href;
  const assessmentId = url
    .split(`/${eventName}/`)[1]
    .substring(0, url?.split(`/${eventName}/`)?.[1]?.indexOf('/org/'));
  return assessmentId;
};

export const getOrgIdFromSharedUrl = () => {
  const urlParts = document.location.href.split('/org/');
  const urlParts1 = urlParts?.[1];
  const orgId = urlParts1?.includes('?')
    ? urlParts1.substring(0, urlParts1.indexOf('?'))
    : urlParts1;
  return orgId;
};

export const getSharedLinkEvent = () => {
  if (isEventSharedLink('comp')) {
    return 'comp';
  }
  if (isEventSharedLink('assess')) {
    return 'assess';
  }
  return '';
};

export const getTypeFromUrl = () => {
  const url = new URL(document.location.href);
  const { searchParams } = url;
  const typeValue = searchParams.get('type');
  return typeValue ?? undefined;
};

export const getAffiliatedParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const orgId = searchParams.get('orgId');
  const isAffiliated =
    searchParams.get('isAffliated') || searchParams.get('isAffiliated');
  const email = searchParams.get('email');
  return { orgId, isAffiliated, email };
};
