import React from 'react';
import PropTypes from 'prop-types';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from '@fifthdomain/fe-shared';

Amplify.configure(amplifyConfig);

const getToken = async () => {
  try {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    return jwtToken;
  } catch (e) {
    return '';
  }
};

const httpLink = createHttpLink({
  uri: amplifyConfig.aws_appsync_graphqlEndpoint,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function GraphQlClient({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

GraphQlClient.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GraphQlClient;
