import React, { useEffect } from 'react';
import { setShowNav } from '@fifthdomain/fe-shared';
import Auth from './components/auth';
import App from './App';

const Root = () => {
  useEffect(() => {
    setShowNav(false);
  }, []);

  return (
    <Auth>
      <App />
    </Auth>
  );
};

export default Root;
