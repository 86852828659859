import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Checkbox } from '@material-ui/core';
import { FdCard } from '@fifthdomain/fe-shared';
import { PrivacyLink, TOSLink, PrivacyAgreeAlert } from './Fragments';
import { ButtonContainer, ButtonBack, ButtonAgree } from './Buttons';

const RegisterPage3 = ({
  Controller,
  control,
  goBack,
  validatePage,
  showPrivacyAlert,
  loading,
}) => (
  <FdCard heading="Privacy and Terms" elevation={24}>
    We collect personal information about you. We use this information to
    improve your experience. We might also share this information with
    organisations including your employer or education provider. You have the
    right to access, correct, erase and make a complaint about your personal
    information. Our Privacy Policy talks more about how we use and protect your
    information.
    <Box mt={2}>Please read and agree to the following before proceeding.</Box>
    <Box mt={2}>
      <Controller
        control={control}
        name="privacyPolicyAgree"
        render={({ field: { ref, value, ...fieldProps } }) => (
          <Box display="flex" alignItems="center">
            <Checkbox inputRef={ref} checked={value} {...fieldProps} />
            <PrivacyLink />
          </Box>
        )}
      />
    </Box>
    <Box>
      <Controller
        control={control}
        name="termsOfServiceAgree"
        render={({ field: { ref, value, ...fieldProps } }) => (
          <Box display="flex" alignItems="center">
            <Checkbox inputRef={ref} checked={value} {...fieldProps} />
            <TOSLink />
          </Box>
        )}
      />
    </Box>
    {showPrivacyAlert && <PrivacyAgreeAlert />}
    <ButtonContainer>
      <ButtonBack onClick={goBack} />
      <ButtonAgree onClick={validatePage} loading={loading} />
    </ButtonContainer>
  </FdCard>
);

RegisterPage3.propTypes = {
  Controller: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  validatePage: PropTypes.func.isRequired,
  showPrivacyAlert: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RegisterPage3;
