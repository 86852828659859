import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CssBaseline, Paper, Hidden } from '@material-ui/core';
import { images } from '@fifthdomain/fe-shared';
import useStyles from './styles';

const AuthLayout = ({ children }) => {
  const classes = useStyles();
  const { PlatformImage } = images;

  return (
    <Grid container component="main">
      <CssBaseline />
      <Hidden only={['xs', 'sm']}>
        <Grid item xs={false} sm={false} md={6} lg={6}>
          <img
            id="fd-login-side-image"
            className={classes.authImage}
            src={PlatformImage}
            alt="Fifth Domain Blue"
            align="right"
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} square>
        {children}
      </Grid>
    </Grid>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthLayout;
