import React from 'react';
import { Link, Box } from '@material-ui/core';
import {
  FdTypography,
  FdAlert,
  PRIVACY_POLICY_URL,
  TOS_URL,
} from '@fifthdomain/fe-shared';

export const PrivacyLink = () => (
  <FdTypography variant="body2">
    {'I agree to FifthDomain’s '}
    <Link
      href={PRIVACY_POLICY_URL}
      underline="always"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy.
    </Link>
  </FdTypography>
);

export const TOSLink = () => (
  <FdTypography variant="body2">
    {'I agree to FifthDomain’s '}
    <Link href={TOS_URL} underline="always" target="_blank" rel="noreferrer">
      Terms and Conditions of Use.
    </Link>
  </FdTypography>
);

export const SubscribeText = () => (
  <FdTypography variant="body2">
    I’d like to receive information about FifthDomain’s products.
  </FdTypography>
);

export const IncompleteAlert = () => (
  <Box my={2}>
    <FdAlert
      variant="error"
      message="Please complete all fields before continuing."
    />
  </Box>
);

export const PrivacyAgreeAlert = () => (
  <Box my={2}>
    <FdAlert
      variant="error"
      message="Please read and agree before proceeding."
    />
  </Box>
);

export const ConsentAlert = () => (
  <Box my={2}>
    <FdAlert
      variant="error"
      message="A parent must consent in order for you to create an account."
    />
  </Box>
);
