/* eslint-disable no-nested-ternary */
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
/* eslint-disable import/no-unresolved */
import { DEFAULT_THEME, withSnackbarProvider } from '@fifthdomain/fe-shared';
import AuthLayout from './components/auth/AuthLayout';
import AuthPageContainer from './components/auth/AuthPageContainer';
import GraphQlClient from './shared/GraphQlClient';
import AuthProvider from './components/auth/AuthProvider';

const generateClassName = createGenerateClassName({
  productionPrefix: 'auth',
  seed: 'auth',
});

const App = () => (
  <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={DEFAULT_THEME}>
      <GraphQlClient>
        <BrowserRouter>
          <AuthProvider>
            <AuthLayout>
              <Switch>
                <Route>
                  <AuthPageContainer />
                </Route>
              </Switch>
            </AuthLayout>
          </AuthProvider>
        </BrowserRouter>
      </GraphQlClient>
    </ThemeProvider>
  </StylesProvider>
);

export default withSnackbarProvider(App);
